import React, { useEffect } from 'react'
import styles from './home.module.scss'
import { useDeviceStore } from '../../stateManager/DeviceStore'
import { DEVICE, PLATFORM, THEME } from '../../_shared/constants/constants'
import Message_header from '../../assets/webp/message_header_desktop.webp'
// import Message_header_Mobile from '../../assets/webp/Message_header_mobile.webp'
import Iphone_desktop from '../../assets/webp/pixel_download.webp'
import Iphone_Mobile from '../../assets/webp/iphone_Message_mobile.webp'
import QR_code from '../../assets/webp/Camera_QR_Code.webp'
import Message_Illustration1 from '../../assets/webp/illustration_1_desktop.webp'
import Message_Illustration1_mobile from '../../assets/webp/illustration_1.1_mobile.webp'
import Message_Illustration2 from '../../assets/webp/illustration_2.webp'
import Message_Illustration2_mobile from '../../assets/webp/illustration_2_mobile.webp'
import Message_Illustration3 from '../../assets/webp/illustration_3.webp'
import Message_Illustration4 from '../../assets/webp/illustartion_4.webp'
import Message_Illustration4_mobile from '../../assets/webp/illustration_4_mobile.webp'
import SetsApart_desktop from '../../assets/webp/setsApart_desktop.webp'
import SetsApart_mobile from '../../assets/webp/setsApart_Mobile.webp'
import Iphone_footer from '../../assets/webp/pixel_Footer.webp'
// import InvisiblePhotos from '../../assets/webp/invisible_photos_mobile.webp'
import { ReactComponent as AppStoreLogo } from '../../assets/svg/appStore_logo.svg'
import { ReactComponent as PlayStoreLogo } from '../../assets/svg/playStore_logo.svg'

import { ReactComponent as MessageFeature1 } from '../../assets/svg/message_feature1.svg'
import { ReactComponent as MessageFeature2 } from '../../assets/svg/message_feature2.svg'
import { ReactComponent as MessageFeature3 } from '../../assets/svg/message_feature3.svg'
import { ReactComponent as MessageFeature4 } from '../../assets/svg/message_feature4.svg'
import { ReactComponent as MessageFeature5 } from '../../assets/svg/message_feature5.svg'
// import { ReactComponent as MessageFeature6 } from '../../assets/svg/message_feature6.svg'
import { Link } from 'react-router-dom'
import { useDownloadHook } from '../../_shared/hooks/download.hook'
import { useOsStore } from '../../stateManager/OsStore'

import { lazily } from 'react-lazily';
const { FloaterDownload } = lazily(() => import('../../_shared/components/FloaterDownload/floaterDownload.component'));

function Home() {
    const { device, setDevice } = useDeviceStore();
    const {
        handleBuildDownload,
    } = useDownloadHook();
    const { os } = useOsStore();

    return (
        <div className={styles.homeContainer}>

            <FloaterDownload title='To protect your messages' buttonText='Downalod' mode={`${THEME.LIGHT}`}/>
            <div className={styles.headerContainer}>
                <div className={styles.header}>
                    <div className={styles.heading}>
                        Keep your private life <span>private.</span>
                    </div>
                    <div className={styles.subHeading}>
                        Seamless Conversations with Total Privacy.
                    </div>

                </div>
                <div className={styles.headerImg}>
                    <img src={device == DEVICE.MOBILE ? `${Message_header}` : `${Message_header}`} alt='Zunu mail Header' />
                </div>
            </div>

            <div className={styles.mailContentContainer}>
                <div className={styles.downloadContainer}>
                    <div className={styles.downloadLeftContainer}>
                        <p className={styles.downloadHeader}>Download Zunu Message now and keep conversations completely private.</p>

                        <div className={styles.download_Os_container}>
                        {device !== DEVICE.MOBILE &&
                            <div className={styles.downloadBtnContainer}>
                           
                                <button className={styles.downloadBtn} onClick={() => handleBuildDownload(os)}>Download Now</button>
                                
                                    <div className={styles.osLogoContainer}>
                                        <Link to="" onClick={() => handleBuildDownload(PLATFORM.AND)}><PlayStoreLogo className={styles.osLogos} /></Link>
                                    </div>
                                
                            </div>
                                }
                        </div>

                    </div >
                    <div className={styles.downloadImgContainer}>
                        <img src={device == DEVICE.MOBILE ? `${Iphone_desktop}` : `${Iphone_desktop}`} />
                    </div>


                    {
                        device == DEVICE.MOBILE &&
                        <div className={styles.qrAndLogoContainer}>
                            <div className={styles.osLogoContainer}>
                                <Link to="" onClick={() => handleBuildDownload(PLATFORM.AND)}><PlayStoreLogo className={styles.osLogos} /></Link>
                            </div>
                        </div>
                    }
                </div >

            </div >
            <div className={`${styles.mailFeatures_Container}  ${styles.mailContentContainer}`}>
                <div className={styles.mailFeatures_header}>
                    You Message Stays Private and Protected.
                </div>
                <div className={styles.mail_Features_content}>
                    {/* <div className={styles.featureContainer}> */}
                    <div className={styles.mail_feature}>
                        <MessageFeature1 className={styles.featureIcons} />
                        <div className={styles.descriptionTextContainer}>
                            <p className={styles.featureDescription}>
                                Protected, Invisible Messages:
                            </p>
                            <p className={styles.featureDescriptionText}>Messaging the way it should be—secure, private, and fully in user control.</p>
                        </div>
                    </div>
                    <div className={styles.mail_feature}>
                        <MessageFeature2 className={styles.featureIcons} />
                        <div className={styles.descriptionTextContainer}>
                            <p className={styles.featureDescription}>
                                Defence Against Hackers:
                            </p>
                            <p className={styles.featureDescriptionText}>Messages are protected from ransomware, malware, and viruses.</p>
                        </div>
                    </div>
                    {/* </div> */}
                    {/* <div className={styles.featureContainer}> */}
                    <div className={styles.mail_feature}>
                        <MessageFeature3 className={styles.featureIcons} />
                        <div className={styles.descriptionTextContainer}>
                            <p className={styles.featureDescription}>
                                Secure on Device:
                            </p>
                            <p className={styles.featureDescriptionText}>Prevents third-party apps with SMS inbox access from reading messages.</p>
                        </div>
                    </div>
                    <div className={styles.mail_feature}>
                        <MessageFeature4 className={styles.featureIcons} />
                        <div className={styles.descriptionTextContainer}>
                            <p className={styles.featureDescription}>
                                Confident Communication:
                            </p>
                            <p className={styles.featureDescriptionText}>Messages remain private, even if you or the recipient lose the device.</p>
                        </div>
                    </div>
                    {/* </div> */}
                    {/* <div className={styles.featureContainer}> */}
                    <div className={styles.mail_feature}>
                        <MessageFeature5 className={styles.featureIcons} />
                        <div className={styles.descriptionTextContainer}>
                            <p className={styles.featureDescription}>
                                Secure over Network:
                            </p>
                            <p className={styles.featureDescriptionText}>Messages are encrypted in transit, hidden from telecom providers.</p>
                        </div>
                    </div>

                    {/* </div> */}
                </div>
            </div>

            <div className={`${styles.illustrationContainer} ${styles.mailContentContainer}`}>
                <div className={`${styles.mailFeatures_header}  ${styles.mailFeatures1_header}`}>
                    Protected, Invisible Messages
                </div>

                <div className={styles.illustration1Image}>
                    <img src={device == DEVICE.MOBILE ? `${Message_Illustration1_mobile}` : `${Message_Illustration1}`} alt='mail illustration' />
                </div>

            </div>
            <div className={`${styles.mailPrivacyContainer} ${styles.mailContentContainer}`}>
                <div className={`${styles.mailFeatures_header} `}>
                    Secure on Device
                </div>
                <div className={styles.ilustration4img}>
                    <p className={styles.illustration4Text}>Message Privacy Assured: Apps with SMS Inbox access cant access messages</p>
                    <img src={device == DEVICE.MOBILE ? `${Message_Illustration2_mobile}` : `${Message_Illustration2}`} alt='illustration' />
                </div>
            </div>

            <div className={`${styles.illustration1Container} ${styles.mailContentContainer}`}>
                <div className={styles.mailFeatures_header}>
                    Secure Over Network
                </div>
                <div className={`${styles.illustrationText3Img}`}>
                    <div className={styles.illustration3Text}>
                        <p className={`${styles.illustrationSubHeader} ${styles.illustrationSubHeader3}`}>Secure <span>Messaging:</span> Hidden <span>and</span> Protected <span>on the Network.</span></p>
                    </div>
                    <div className={styles.illustration3}>
                        <img src={Message_Illustration3} alt='mail illustration' />
                    </div>
                </div>
            </div>

            <div className={`${styles.illustrationContainer} ${styles.mailContentContainer}`}>
                <div className={styles.mailFeatures_header}>
                    Message Confidently
                </div>
                <div className={styles.illustrationTextImg}>
                    <div className={styles.illustrationText}>
                        {device !== DEVICE.MOBILE ?
                            <p className={styles.illustrationSubHeader}><span>Message</span> confidentiality <span>and</span> privacy <span>are maintained even during</span>  device loss <span>and</span> password compromise.</p>
                            :
                            <p className={styles.illustrationSubHeader}>Message confidentiality and privacy are maintained even during  device loss and password compromise.</p>
                        }
                    </div>
                    <div className={styles.illustrationImage}>
                        <img src={device == DEVICE.MOBILE ? `${Message_Illustration4_mobile}` : `${Message_Illustration4}`} alt='mail illustration' />
                    </div>
                </div>
            </div>
            <div className={`${styles.setUsApartContainer} ${styles.mailContentContainer}`}>
                <div className={styles.setApartHeader}>
                    <div className={styles.mailFeatures_header}>
                        What sets us Apart?
                    </div>
                    <div className={styles.mailFeatureSubHeader}>Our ultimate robust Device-to-Device End-to-End Encryption for 100% Privacy</div>

                </div>
                <div className={styles.setApartImg}>
                    <img src={device == DEVICE.MOBILE ? `${SetsApart_mobile}` : `${SetsApart_desktop}`} alt='illustration' />
                </div>
            </div>

            <div className={styles.footerContainer}>
                <div className={styles.footerLeftContainer}>
                    <p className={styles.footerText_header}>Download Zunu Message now and keep conversations completely private.</p>
                    <div className={styles.download_Qr_scanner}>
                        {device !== DEVICE.MOBILE &&
                            <div className={styles.footerDownload_container}>
                                <div className={styles.footerBtn_container}>
                                    <button className={styles.downloadBtn} onClick={() => handleBuildDownload(os)}>Download Now</button>
                                </div>
                                {device !== DEVICE.MOBILE &&
                                    <div className={styles.footerLogoContainer}>
                                        <Link to="" onClick={() => handleBuildDownload(PLATFORM.AND)}><PlayStoreLogo className={styles.osLogos} /></Link>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>
                <div className={styles.footerImgContainer}>
                    <div className={styles.footerImg}>
                        <img src={device == DEVICE.MOBILE ? `${Iphone_footer}` : `${Iphone_footer}`} alt='pixel mobile' />
                    </div>

                    {device == DEVICE.MOBILE &&
                        <div className={styles.qrAndLogoContainer}>
                            <div className={styles.footerLogoContainer}>
                                <Link to="" onClick={() => handleBuildDownload(PLATFORM.AND)}><PlayStoreLogo className={styles.osLogos} /></Link>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div >
    )
}

export { Home }